export default {
    data() {
        return {
            vehicleMixin: [
                this.$i18n.t("Car"),
                this.$i18n.t("Van"),
                this.$i18n.t("Truck"),
                this.$i18n.t("TractorUnit"),
                this.$i18n.t("Semitrailer"),
                this.$i18n.t("Fulltrailer"),
                this.$i18n.t("HCT-combination"),
                this.$i18n.t("Bus"),
                this.$i18n.t("Machine"),
            ],
        };
    },
};
