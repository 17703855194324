<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="12">
                <v-img
                    src="/img/logos/Logo_2.png"
                    class="my-3"
                    contain
                    height="150"
                />
            </v-col>

            <v-col class="mb-4">
                <h1 class="display-2 font-weight-bold mb-3">
                    {{ $t("Welcome") }}
                </h1>

                <p class="subheading font-weight-regular">
                    {{ $t("paragraphs1", { currency }) }}
                </p>
                <p class="subheading font-weight-regular">
                    {{ $t("paragraphs2") }}
                </p>
                <p class="subheading font-weight-regular">
                    {{ $t("paragraphs3") }}
                </p>
                <user-sign-out />
            </v-col>

            <v-col class="mb-5" cols="12">
                <v-row justify-center>
                    <v-col>
                        <v-btn
                            x-large
                            color="primary"
                            router
                            to="calculations"
                            >{{ $t("Start") }}</v-btn
                        >
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import UserSignOut from "@/components/UserSignOut";
import { defineComponent } from "vue";

export default defineComponent({
    name: "WelcomePage",

    components: { UserSignOut },
    data() {
        return {
            currency: "€",
        };
    },

    created() {},

    methods: {},
});

</script>
<style scoped></style>
