export default {
    data() {
        return {
            bonuses: [
                {
                    text: this.$t('bonuses.adr'),
                    value: 'adr'
                },
                {
                    text: this.$t('bonuses.crane'),
                    value: 'crane'
                },
                {
                    text: this.$t('bonuses.dirtyJob'),
                    value: 'dirtyJob'
                },
                {
                    text: this.$t('bonuses.hct'),
                    value: 'hct'
                }
            ]
        }
    },
}