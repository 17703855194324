export default {
    data() {
        return {
            defaultWages: {
                eveningWorkSupplementPercentage: 0.15,
                nightWorkSupplementPercentage: 0.2,
                sundayWorkSupplementPercentage: 1.0,
                overTimeSupplementUnder12hPercentage: 0.5,
                overTimeSupplementOver12hPercentage: 1.0,
                sickLeaveSupplementPercentage: 0.03,
                vacationSupplementPercentage: 0.185,
                talSupplementPercentage: 0.075,
                employerPensionInsuranceContributionPercentage: 0.18,
                groupLifeInsuranceContributionPercentage: 0.07/100,
                employerSocialInsuranceContributionPercentage: 0.0153,
                occupationalAccidentInsuranceContributionPercentage: 0.03,
                unemploymentInsurancePercentageLowerValue: 0.005,
                unemploymentInsurancePercentageUpperValue: 0.019,
                unemploymentInsuranceAnnualSalaryOrganisationLimit: 2169000,
                fullDomesticDailyallowance: 35.70,
                halfDomesticDailyAllowance: 16.20,
                increasedDomesticFullDailyAllowance: 45.00,
                fullAbroadDailyAllowance: 61.10,
                halfAbroadDailyAllowance: 34.80,
                increasedAbroadFullDailyAllowance: 65.30
            }
        }
    }
}