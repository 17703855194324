<template>
    <div class="d-flex">
        <div class="d-flex align-center mr-4">
            <v-tooltip :text="$t('surveyButtonTooltip')" location="start">
                <template #activator="{ props }">
                    <a
                        href="https://form.trustmary.com/C4cki0x2l"
                        target="_blank"
                    >
                        <v-btn
                            variant="plain"
                            color="primary"
                            icon="mdi-message-alert-outline"
                            size="regular"
                            v-bind="props"
                        ></v-btn>
                    </a>
                </template>
            </v-tooltip>
        </div>
        <v-menu v-if="$vuetify.display.smAndDown" bottom left offset-y>
            <template #activator="{ props }">
                <v-btn icon v-bind="props">
                    <v-icon>mdi-menu</v-icon>
                </v-btn>
            </template>

            <v-list class="list">
                <div class="ml-4 py-3">
                    <v-subheader>{{ oidcUser.name }}</v-subheader>
                </div>
                <v-list-item
                    link
                    router
                    to="/userprofile"
                    class="d-flex flex-row"
                >
                    <div class="d-flex flex-row align-center">
                        <v-list-item-icon class="menu-icon mr-2">
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('user') }}</v-list-item-title>
                    </div>
                </v-list-item>
                <v-list-item
                    v-if="roles && roles.includes('Admin')"
                    link
                    router
                    to="/administration"
                >
                    <div class="d-flex flex-row align-center py-3">
                        <v-list-item-icon class="menu-icon mr-2">
                            <v-icon>mdi-card-bulleted</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{
                            $t('Administration')
                        }}</v-list-item-title>
                    </div>
                </v-list-item>
                <v-list-item
                    v-if="
                        roles &&
                        (roles.includes('Admin') || roles.includes('Superuser'))
                    "
                    link
                    router
                    to="/organisation"
                >
                    <div class="d-flex flex-row align-center py-3">
                        <v-list-item-icon class="menu-icon mr-2">
                            <v-icon>mdi-office-building-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{
                            $t('organisation')
                        }}</v-list-item-title>
                    </div>
                </v-list-item>
                <v-list-item link router to="/contact-us">
                    <div class="d-flex flex-row align-center py-3">
                        <v-list-item-icon class="menu-icon mr-2">
                            <v-icon>mdi-email-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{
                            $t('contactPage.contact')
                        }}</v-list-item-title>
                    </div>
                </v-list-item>
                <v-list-item link @click="$store.dispatch('logout')">
                    <div class="d-flex flex-row align-center py-3">
                        <v-list-item-icon class="menu-icon mr-2">
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{
                            $t('Sign out')
                        }}</v-list-item-title>
                    </div>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-list
            v-else
            class="d-flex flex-row justify-end align-center nav-list"
        >
            <li class="pr-12">
                <a class="cursor link-style" href="/organisation">{{
                    $t('organisation')
                }}</a>
            </li>
            <li class="pr-12">
                <a class="cursor link-style" href="/contact-us">{{
                    $t('Contact')
                }}</a>
            </li>
            <li v-if="roles && roles.includes('Admin')" class="pr-12">
                <a class="cursor link-style" href="/administration">{{
                    $t('Administration')
                }}</a>
            </li>
            <li class="pr-12">
                <v-menu bottom left offset-y transition="slide-y-transition">
                    <template #activator="{ props }">
                        <div v-bind="props" class="circle primary">
                            <span class="text-white">{{ name }}</span>
                        </div>
                    </template>
                    <v-list class="mt-3">
                        <v-list-item
                            link
                            router
                            to="/userprofile"
                            class="d-flex flex-row"
                        >
                            <div class="d-flex flex-row align-center">
                                <v-list-item-icon class="menu-icon mr-2">
                                    <v-icon>mdi-account</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{
                                    $t('Account setting')
                                }}</v-list-item-title>
                            </div>
                        </v-list-item>
                        <v-list-item link @click="$store.dispatch('logout')">
                            <div class="d-flex flex-row align-center py-3">
                                <v-list-item-icon class="menu-icon mr-2">
                                    <v-icon>mdi-logout</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{
                                    $t('Sign out')
                                }}</v-list-item-title>
                            </div>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </li>
        </v-list>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'UserOptions',
    computed: {
        ...mapGetters('oidcStore', ['oidcUser']),
        name() {
            return this.oidcUser ? this.oidcUser.name.charAt(0) : '';
        },
    },
    methods: {
        ...mapActions('oidcStore', ['signOutOidc']),
    },
};
</script>

<style lang="scss" scoped>
:deep(.v-list-item__icon:first-child) {
    margin-right: 16px;
}
.list {
    width: 200px !important;
}
.nav-list {
    list-style: none;
    width: 100%;
}
.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px; /* Set the width and height as needed */
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    text-decoration: none;
}

.circle span {
    font-weight: 700;
    font-size: 22px;
    text-transform: uppercase;
}
.link-style {
    text-decoration: none;
    color: #333;
}
.link-style:hover {
    color: #999;
}
</style>
