import api from './api';
import cache from './cacheFunction';

export default {
    getUser() {
        return api.get('account/me');
    },
    get() {
        return api.get('Organisation/');
    },
    getAll() {
        return api.get('Organisation/all');
    },
    update(organisation) {
        cache.deleteAll();
        return api.put('Organisation/', organisation);
    },
    create(organisation) {
        cache.deleteAll();
        return api.post('Organisation', organisation);
    },
    setToBeDeleted(organisation, mode) {
        cache.deleteAll();
        return api.put('Organisation/' + organisation.id + '/SetDelete', mode, {
            headers: { 'Content-type': 'application/json' },
        });
    },
    getUsers() {
        return api.get('Organisation/Users');
    },
    updateUser(user) {
        cache.deleteAll();
        return api.put(
            'Organisation/' + user.organisationId + '/users/' + user.id,
            user,
        );
    },
    deleteUser(user) {
        cache.deleteAll();
        return api.delete(
            'Organisation/' + user.organisationId + '/users/' + user.id,
        );
    },
    getExt() {
        return api.get('Organisation/ExternalCredentials');
    },
    postExt(ext) {
        cache.deleteAll();
        return api.post('Organisation/ExternalCredentials', ext);
    },
    putExt(ext) {
        cache.deleteAll();
        return api.put('Organisation/ExternalCredentials', ext);
    },
    delExt(orgId, ext) {
        cache.deleteAll();
        return api.delete('Organisation/' + orgId + '/ExternalCredentials', {
            data: {
                ...ext,
            },
        });
    },
    fetchAvailableVehicles(ext) {
        return api.post('Organisation/FetchExternalVehicles', ext);
    },
    fetchAvailableDrivers(ext) {
        return api.post('Organisation/FetchExternalDrivers', ext);
    },
    getExternalVehicles(serviceName) {
        let params = { serviceName };
        return api.get('Organisation/ExternalVehicles', { params });
    },
    postExternalVehicle(vehicle, serviceName, orgId) {
        cache.deleteAll();
        let params = { serviceName, organisationId: orgId };
        return api.post('Organisation/ExternalVehicles', vehicle, { params });
    },
    deleteExternalVehicle(registerNumber, orgId) {
        cache.deleteAll();
        let params = { registerNumber, organisationId: orgId };
        return api.delete('Organisation/ExternalVehicles', { params });
    },
    fetchExtTasks(ext) {
        cache.deleteAll();
        let params = {
            serviceName: ext,
        };
        return api.put('Organisation/FetchExternalData', null, {
            params: params,
        });
    },
    getFetchStatus(ext, vehicles) {
        let params = { serviceName: ext, vehicles };

        return api.get('Organisation/FetchStatus', {
            params: params,
        });
    },
    getFetchDriverStatus(ext, drivers) {
        let params = { serviceName: ext, drivers };

        return api.get('Organisation/FetchDriverStatus', {
            params: params,
        });
    },
    getExtToken(ext, orgId) {
        let params = { organisationId: orgId };
        return api.post('Organisation/GetAccessToken', ext, { params });
    },
    deleteExternalTasks(orgId) {
        cache.deleteAll();
        return api.delete('Organisation/' + orgId + '/tasks', {
            params: {
                source: 'external',
            },
        });
    },
    deleteInternalTasks(orgId) {
        cache.deleteAll();
        return api.delete('Organisation/' + orgId + '/tasks', {
            params: {
                source: 'internal',
            },
        });
    },
    deleteExternalInvoices(orgId) {
        cache.deleteAll();
        return api.delete('Organisation/' + orgId + '/invoices', {
            params: {
                source: 'external',
            },
        });
    },
    deleteInternalInvoices(orgId) {
        cache.deleteAll();
        return api.delete('Organisation/' + orgId + '/invoices', {
            params: {
                source: 'internal',
            },
        });
    },
    getSiteUsers() {
        return api.get('Organisation/All/Users');
    },
    getVehicleDetails() {
        return api.get('Organisation/VehicleDetails');
    },
    updateVehicleDetail(detail) {
        cache.deleteAll();
        return api.put(`Organisation/VehicleDetails/${detail.id}`, detail);
    },
    postVehicleDetail(detail) {
        cache.deleteAll();
        return api.post('Organisation/VehicleDetails', detail);
    },
    getFeatures() {
        const fetchApi = api.get('Organisation/Features');
        return cache.getAll('features', 60 * 60 * 1000, fetchApi);
    },
    getAllFeatures() {
        return api.get('Organisation/All/Features');
    },
    getFuelPrices() {
        return api.get('Organisation/FuelPrices');
    },
    createFuelPrices(fuelPrice) {
        cache.deleteAll();
        return api.post('Organisation/FuelPrices', fuelPrice);
    },
    updateFuelPrices(fuelPrice) {
        cache.deleteAll();
        return api.put(`Organisation/FuelPrices/${fuelPrice.id}`, fuelPrice);
    },
    getFuelHistory() {
        return api.get('Organisation/FuelHistory');
    },
    getWageDetails() {
        return api.get('Organisation/WageDetails');
    },
    createWageDetails(details) {
        cache.deleteAll();
        return api.post('Organisation/WageDetails', details);
    },
    updateWageDetails(details) {
        cache.deleteAll();
        return api.put('Organisation/WageDetails', details);
    },
    tryVolvoConnect(username, password) {
        return api.post('organisation/tryvolvoconnect', {
            username: username,
            password: password,
        });
    },
    createSalesforceTicket(info) {
        return api.post('/SalesForce', {
            company: info.company,
            name: info.name,
            email: info.email,
            phone: info.phone,
            subject: info.subject,
            description: info.description,
        });
    },
    createPayrollCodes(salaryCodes) {
        cache.deleteAll();
        return api.post('/SalaryTypeCodes/', salaryCodes);
    },
    getPayrollCodes() {
        return api.get('/SalaryTypeCodes/');
    },
    updatePayrollCodes(salaryCodes) {
        cache.deleteAll();
        return api.put('SalaryTypeCodes/', salaryCodes);
    },
    getShiftAmounts() {
        return api.get('Organisation/Shifts/');
    },
};
