export default {
    data() {
        return {
            vehicleDefaultValues: [
                {
                    vehicleClass: 1,
                    name: 'Car',
                    image: '/img/icons/car.png',
                    maintenanceCost: 0.002,
                    fuelCost: 1.1,
                    fuelType: 0,
                    interestRate: 2.5,
                    investmentDeductions: 100,
                    vehicleTax: 580,
                    residualValue: 16,
                    insuranceAllRisk: 700,
                    insuranceVehicle: 300,
                    tyresCost: 125,
                    tyreCost: 0.001,
                    desc: '',
                    flex: 3,
                    countOfTyres: 4,
                    category: 0,
                    adBluePrice: 5,
                    nickname: '',
                    base: '',
                },
                {
                    vehicleClass: 2,
                    name: 'Van',
                    image: '/img/icons/van.png',
                    maintenanceCost: 0.002,
                    fuelCost: 1.1,
                    fuelType: 0,
                    interestRate: 2.5,
                    investmentDeductions: 150,
                    vehicleTax: 274,
                    residualValue: 16,
                    insuranceAllRisk: 700,
                    insuranceVehicle: 300,
                    tyresCost: 175,
                    tyreCost: 0.001,
                    desc: '',
                    //calculationId: 0,
                    flex: 3,
                    countOfTyres: 4,
                    category: 0,
                    adBluePrice: 5,
                    nickname: '',
                    base: '',
                },
                {
                    vehicleClass: 3,
                    name: 'Truck',
                    image: '/img/icons/truck.png',
                    maintenanceCost: 0.002,
                    fuelCost: 1.1,
                    fuelType: 0,
                    interestRate: 2.5,
                    investmentDeductions: 400,
                    vehicleTax: 927,
                    residualValue: 16,
                    insuranceAllRisk: 1300,
                    insuranceVehicle: 1300,
                    tyresCost: 0.001,
                    tyreCost: 0.001,
                    desc: '',
                    //calculationId: 0,
                    flex: 3,
                    countOfTyres: 8,
                    category: 0,
                    adBluePrice: 5,
                    nickname: '',
                    base: '',
                },
                {
                    vehicleClass: 4,
                    name: 'TractorUnit',
                    image: '/img/icons/tractor-unit.png',
                    maintenanceCost: 0.002,
                    fuelCost: 1.1,
                    fuelType: 0,
                    interestRate: 2.5,
                    investmentDeductions: 400,
                    vehicleTax: 1328,
                    residualValue: 16,
                    insuranceAllRisk: 1300,
                    insuranceVehicle: 1300,
                    tyresCost: 500,
                    tyreCost: 0.001,
                    desc: '',
                    //calculationId: 0,
                    flex: 3,
                    countOfTyres: 8,
                    category: 0,
                    adBluePrice: 5,
                    nickname: '',
                    base: '',
                },
                {
                    vehicleClass: 5,
                    name: 'Semitrailer',
                    image: '/img/icons/semi-trailer.png',
                    maintenanceCost: 0.002,
                    fuelCost: 1.1,
                    fuelType: 0,
                    interestRate: 2.5,
                    investmentDeductions: 400,
                    vehicleTax: 1328,
                    residualValue: 16,
                    insuranceAllRisk: 1400,
                    insuranceVehicle: 1400,
                    tyresCost: 357.14,
                    tyreCost: 0.001,
                    desc: '',
                    //calculationId: 0,
                    flex: 3,
                    countOfTyres: 14,
                    category: 0,
                    adBluePrice: 5,
                    nickname: '',
                    base: '',
                },
                {
                    vehicleClass: 6,
                    name: 'Fulltrailer',
                    image: '/img/icons/full-trailer.png',
                    maintenanceCost: 0.002,
                    fuelCost: 1.1,
                    fuelType: 0,
                    interestRate: 2.5,
                    investmentDeductions: 400,
                    vehicleTax: 1328,
                    residualValue: 16,
                    insuranceAllRisk: 1400,
                    insuranceVehicle: 1400,
                    tyresCost: 333.33,
                    tyreCost: 0.001,
                    desc: '',
                    //calculationId: 0,
                    flex: 3,
                    countOfTyres: 18,
                    category: 0,
                    adBluePrice: 5,
                    nickname: '',
                    base: '',
                },
                {
                    vehicleClass: 7,
                    name: 'HCT-combination',
                    image: '/img/icons/hct-combination.png',
                    maintenanceCost: 0.002,
                    fuelCost: 1.1,
                    fuelType: 0,
                    interestRate: 2.5,
                    investmentDeductions: 400,
                    vehicleTax: 1328,
                    residualValue: 16,
                    insuranceAllRisk: 1400,
                    insuranceVehicle: 1400,
                    tyresCost: 363.64,
                    tyreCost: 0.001,
                    desc: '',
                    //calculationId: 0,
                    flex: 3,
                    countOfTyres: 22,
                    category: 0,
                    adBluePrice: 5,
                    nickname: '',
                    base: '',
                },
                {
                    vehicleClass: 8,
                    name: 'Bus',
                    image: '/img/icons/bus.png',
                    maintenanceCost: 0.002,
                    fuelCost: 1.1,
                    fuelType: 0,
                    interestRate: 2.5,
                    investmentDeductions: 400,
                    vehicleTax: 1328,
                    residualValue: 16,
                    insuranceAllRisk: 1300,
                    insuranceVehicle: 1300,
                    tyreCost: 0.001,
                    desc: '',
                    // calculationId: 0,
                    flex: 3,
                    countOfTyres: 6,
                    category: 0,
                    adBluePrice: 5,
                    nickname: '',
                    base: '',
                },
                {
                    vehicleClass: 9,
                    name: 'Machine',
                    image: '/img/icons/excavator.png',
                    maintenanceCost: 0.002,
                    fuelCost: 1.1,
                    fuelType: 0,
                    interestRate: 2.5,
                    investmentDeductions: 100,
                    vehicleTax: 580,
                    residualValue: 16,
                    insuranceAllRisk: 700,
                    insuranceVehicle: 300,
                    tyreCost: 0.001,
                    desc: '',
                    //calculationId: 0,
                    flex: 3,
                    countOfTyres: 4,
                    category: 1,
                    adBluePrice: 5,
                    nickname: '',
                    base: '',
                },
            ],
        };
    },
};
