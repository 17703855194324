export default {
    data() {
        return {
            icons: [
                "/img/icons/car.png",
                "/img/icons/van.png",
                "/img/icons/truck.png",
                "/img/icons/tractor-unit.png",
                "/img/icons/semi-trailer.png",
                "/img/icons/full-trailer.png",
                "/img/icons/hct-combination.png",
                "/img/icons/bus.png",
                "/img/icons/excavator.png",
            ],
        }
    }
}