import { mapState } from "vuex";

export default {
    data() {
        return {
            privileged: [
                "ff89b227-7412-42eb-63cf-08d8d7cadceb",
                "cc58643a-8123-4b5a-cdbc-08d8bde6ed0f",
            ],
        };
    },
    computed: {
        ...mapState(["organisationId"]),
        canViewFull() {
            return this.privileged.includes(this.organisationId);
        },
    },
};
