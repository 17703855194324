<template>
    <div v-if="oidcUser">
        <span v-if="$vuetify.breakpoint.mdAndUp"
            >{{ $t("Signed in as") }} {{ oidcUser.name }}</span
        >
        <v-btn @click="signOutOidc">{{ $t("Sign out") }}</v-btn>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
    name: "UserSignOut",

    computed: {
        ...mapGetters("oidcStore", ["oidcUser"]),
    },
    methods: {
        ...mapActions("oidcStore", ["signOutOidc"]),
    },
});
</script>
