export default {
    data() {
        return {
            dailyAllowance: [
                {
                    text: this.$t('dailyAllowances.none'),
                    value: 0
                },
                {
                    text: this.$t('dailyAllowances.fullDomestic'),
                    value: 1
                },
                {
                    text: this.$t('dailyAllowances.halfDomestic'),
                    value: 2
                },
                {
                    text: this.$t('dailyAllowances.increasedDomesticFull'),
                    value: 3
                },
                {
                    text: this.$t('dailyAllowances.fullAbroad'),
                    value: 4
                },
                {
                    text: this.$t('dailyAllowances.halfAbroad'),
                    value: 5
                },
                {
                    text: this.$t('dailyAllowances.increasedAbroadFull'),
                    value: 6
                }
            ]
        };
    },
};