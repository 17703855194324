<template>
    <v-app id="inspire">
        <v-app-bar app clipped-left fixed>
            <div class="d-flex flex-row justify-space-between app-bar mx-2">
                <div class="d-flex flex-row align-center">
                    <v-btn
                        v-if="$vuetify.display.mdAndUp"
                        color="primary"
                        variant="text"
                        @click.stop="drawer = !drawer"
                    >
                        <v-icon icon="mdi-menu" size="x-large"></v-icon>
                    </v-btn>
                    <v-app-bar-nav-icon
                        v-if="$vuetify.display.smAndDown"
                        @click.stop="onClickNavBar"
                    />
                    <a v-once href="/" class="apptitle">
                        <v-img
                            alt="Diffo Logo"
                            class="shrink mr-2 d-flex flex-row justify-space-between"
                            contain
                            src="/img/logos/Logo_1.png"
                            transition="scale-transition"
                            width="50"
                        />
                        <v-responsive
                            class="mt-2"
                            :class="
                                $vuetify.display.smAndUp ? '' : 'title_font'
                            "
                            >{{ title }}
                        </v-responsive>
                    </a>
                </div>
                <user-options />
            </div>
        </v-app-bar>

        <v-main>
            <profitSidebar :drawer="drawer"></profitSidebar>
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<script>
import ProfitSidebar from './components/ProfitSideBar';
import { mapGetters, mapActions } from 'vuex';
import store from '@/store';
import UserOptions from '@/components/UserOptions';
import Api from './api';
import OrganisationApi from './organisationApi';
import Currencies from './mixins/currencyMixin';
import cache from './cacheFunction';
import { appInsights } from './main';
import organisationApi from './organisationApi';

export default {
    name: 'App',
    components: {
        UserOptions,
        ProfitSidebar,
    },
    mixins: [Currencies],
    props: {
        source: String,
    },
    data() {
        return {
            drawer: true,
            languages: [
                { flag: 'gb', language: 'en', title: 'English' },
                { flag: 'fi', language: 'fi', title: 'Suomi' },
                { flag: 'se', language: 'sv', title: 'Swedish' },
            ],
            title: process.env.VUE_APP_TITLE,
            currentYear: new Date().getFullYear(),
        };
    },
    computed: {
        ...mapGetters('oidcStore', [
            'oidcIsAuthenticated',
            'oidcAccessTokenExp',
        ]),
        hasAccess: function () {
            return this.oidcIsAuthenticated || this.$route.meta.isPublic;
        },
    },
    watch: {
        '$store.state.currentCalcId': function () {
            if (
                this.$store.state.currentCalcId &&
                localStorage.currentCalcId != this.$store.state.currentCalcId
            ) {
                localStorage.currentCalcId = this.$store.state.currentCalcId;
            }
        },
        '$store.state.oidcStore.access_token': function () {
            Api.updateToken(this.$store.state.oidcStore.access_token);
            this.$store.dispatch('getFeatures', organisationApi);
        },
    },
    created() {
        let userLang = (
            navigator.language || navigator.userLanguage
        ).toLowerCase();
        this.setUserLang(userLang);
        this.$vuetify.theme.dark = false;
        this.$i18n.currency =
            userLang === 'sv'
                ? this.currencies[2].symbol
                : this.currencies[0].symbol;
        appInsights.addTelemetryInitializer((envelope) => {
            envelope.tags['ai.cloud.role'] = 'Vue App';
        });
    },
    mounted() {
        window.addEventListener('vuexoidc:userLoaded', this.userLoaded);
        window.addEventListener('vuexoidc:userLoaded', this.saveClaimsData);
        window.addEventListener('vuexoidc:oidcError', this.oidcError);
        if (Number(localStorage.currentCalcId)) {
            this.$store.state.currentCalcId = Number(
                localStorage.currentCalcId,
            );
        }
    },
    unmounted() {
        window.removeEventListener('vuexoidc:userLoaded', this.userLoaded);
        window.removeEventListener('vuexoidc:userLoaded', this.saveClaimsData);
        window.removeEventListener('vuexoidc:oidcError', this.oidcError);
    },
    methods: {
        onClickNavBar() {
            this.drawer = !this.drawer;
        },
        setUserLang(language) {
            if (language.includes('fi')) {
                this.$i18n.locale = 'fi';
                this.$i18n.flag = 'fi';
                this.$i18n.title = 'Suomi';
            } else if (language.includes('sv')) {
                this.$i18n.locale = 'sv';
                this.$i18n.flag = 'se';
                this.$i18n.title = 'Swedish';
            } else {
                this.$i18n.locale = 'en';
                this.$i18n.flag = 'gb';
                this.$i18n.title = 'English';
            }
        },
        setLocale(entry) {
            this.$i18n.locale = entry.language;
            this.$i18n.flag = entry.flag;
            this.$i18n.title = entry.title;
            this.hideDropdown();
        },
        goHome() {
            this.$router.push('/');
        },
        ...mapActions('oidcStore', ['authenticateOidcPopup', 'removeOidcUser']),
        userLoaded: function (e) {
            console.log(
                'I am listening to the user loaded event in vuex-oidc',
                e.detail,
            );
        },
        oidcError: function (e) {
            cache.deleteAll();
            console.log(
                'I am listening to the oidc error event in vuex-oidc',
                e.detail,
            );
        },
        signOut: function () {
            this.removeOidcUser().then(() => {
                this.$router.push('/');
            });
        },
        saveClaimsData: function (e) {
            var decoded;
            if (!Api.hasToken()) {
                Api.updateToken(
                    e.detail?.access_token
                        ? e.detail.access_token
                        : store.state.oidcStore.access_token,
                );
            }

            if (e.detail) {
                if (e.detail.access_token) {
                    decoded = this.parseJwt(e.detail.access_token);
                }
            } else {
                decoded = this.parseJwt(store.state.oidcStore.access_token);
            }

            if (decoded) {
                appInsights.setAuthenticatedUserContext(
                    decoded.id,
                    decoded.OrganisationId,
                );
                if (decoded.Role) {
                    this.$store.commit('setRoles', decoded.Role);
                } else this.$store.commit('setRoles', ['User']);
                OrganisationApi.get()
                    .then((res) => {
                        if (res.data) {
                            let currency = this.currencies.find(
                                (item) =>
                                    item.symbol === res.data.currency ||
                                    item.iso == res.data.currency,
                            );
                            this.$i18n.currency = currency
                                ? currency.symbol
                                : this.currencies[0].symbol;
                            let org = { ...res.data };
                            org.currency = currency
                                ? currency.iso
                                : this.currencies[0].iso;
                            this.$store.commit('setOrganisation', org);
                        } else {
                            let currency = this.currencies.find(
                                (item) =>
                                    item.symbol ===
                                        decoded.OrganisationCurrency ||
                                    item.iso == decoded.OrganisationCurrency,
                            );
                            this.$i18n.currency = currency
                                ? currency.symbol
                                : this.currencies[0].symbol;
                            this.$store.commit('setOrganisation', {
                                id: decoded.OrganisationId,
                                name: decoded.OrganisationName,
                                currency: currency
                                    ? currency.iso
                                    : this.currencies[0].iso,
                            });
                        }
                    })
                    .catch((error) => {
                        let currency = this.currencies.find(
                            (item) =>
                                item.symbol === decoded.OrganisationCurrency ||
                                item.iso == decoded.OrganisationCurrency,
                        );
                        this.$i18n.currency = currency
                            ? currency.symbol
                            : this.currencies[0].symbol;
                        this.$store.commit('setOrganisation', {
                            id: decoded.OrganisationId,
                            name: decoded.OrganisationName,
                            currency: currency
                                ? currency.iso
                                : this.currencies[0].iso,
                        });
                        this.catchError(error);
                    });
                OrganisationApi.getFeatures()
                    .then((res) => {
                        if (res.data) {
                            this.$store.commit('setFeatures', res.data);
                        }
                    })
                    .catch((error) => {
                        this.catchError(error);
                    });
                this.$store.state.organisationId = decoded.OrganisationId;
                this.$i18n.locale = decoded.DisplayLanguage
                    ? decoded.DisplayLanguage
                    : 'fi';

                // for some reason, the user is NOT loaded even though we are listening to its event
                /*
            console.log('User: ', store.state.oidcStore.user)
            store.state.oidcStore.user.language = decoded.DisplayLanguage
            */
                console.log('Currency: ', decoded.OrganisationCurrency);
            }
        },
        atou: function (b64) {
            return decodeURIComponent(escape(atob(b64)));
        },
        parseJwt: function (token) {
            try {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace('-', '+').replace('_', '/');
                return JSON.parse(this.atou(base64));
            } catch (error) {
                return {};
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.v-responsive {
    font-size: 20px;
}
.app-bar {
    width: 100% !important;
}
</style>
