export default {
    data() {
        return {
            fuelTypes: [
                {
                    name: this.$i18n.t("diesel"),
                    value: 0,
                    costName: "dieselCost",
                    unit: 'l'
                },
                {
                    name: this.$i18n.t("electric"),
                    value: 1,
                    costName: "electricCost",
                    unit: 'kWh'
                },
                {
                    name: this.$i18n.t("fuelOil"),
                    value: 2,
                    costName: "fuelOilCost",
                    unit: 'l'
                },
                {
                    name: this.$i18n.t("renewableDiesel"),
                    value: 3,
                    costName: "renewableDieselCost",
                    unit: 'l'
                },
                {
                    name: this.$i18n.t("adBlue"),
                    value: 4,
                    costName: "adBlueCost",
                    unit: 'l'
                },
                {
                    name: this.$i18n.t("compressed naturalGas"),
                    value: 5,
                    costName: "compressedNaturalGasCost",
                    unit: 'kg'
                },
                {
                    name: this.$i18n.t("Liquefied Natural Gas"),
                    value: 6,
                    costName: "liquefiedNaturalGasCost",
                    unit: 'l'
                },
                {
                    name: this.$i18n.t("compressed bioGas"),
                    value: 7,
                    costName: "compressedBioGasCost",
                    unit: 'kg'
                },
                {
                    name: this.$i18n.t("Liquefied Biogas"),
                    value: 8,
                    costName: "liquefiedBioGasCost",
                    unit: 'l'
                }
            ],
        };
    },
};