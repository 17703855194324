import { get,set, del, clear } from "idb-keyval";

export default {
    async getAll(name, interval, api) {
       await setInterval(() => {
            del(name);
        }, interval);
        
        return get(name).then(items => {
            if (items) {     
                return items;
            } 
            if (!items) {
                return api.then((res) => {
                    set(name, res.data);
                    return get(name).then(cacheItem => cacheItem)
                })
            }   
        })
    },
    deleteAll() {
        clear();
    },
    async set(name, item) {
       await set(name, item);
    }
} 
