import axios from "axios";
import store from "@/store";

const token = store.state.oidcStore.access_token;
const instance = axios.create({
    baseURL: process.env.VUE_APP_DIFFO_API_BASE_URL,
});
if (token) {
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
}
instance.updateToken = function (newToken) {
    this.defaults.headers.common["Authorization"] = "Bearer " + newToken;
};
instance.hasToken = function () {
    return this.defaults.headers.common["Authorization"] ? true : false;
};

export default instance;
