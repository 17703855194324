export default {
    data() {
        return {
            currencies: [
                { iso: "EUR", symbol: "€" },
                { iso: "USD", symbol: "$" },
                { iso: "SEK", symbol: "kr" },
            ],
        };
    },
};
