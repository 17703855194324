

import { createI18n } from 'vue-i18n'
import enLocale from '../src/locales/en.json';
import fiLocale from '../src/locales/fi.json';
import svLocale from '../src/locales/sv.json';


const i18n = createI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'fi',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    flag: "fi",
    title: "fi",
    currency: "fi",
    allowComposition: true, // you need to specify that!
    messages: {
        en: enLocale,
        fi: fiLocale,
        sv: svLocale
    },
    silentFallbackWarn: true,
    silentTranslationWarn:true
})

export default i18n;
