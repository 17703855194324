import i18n from "../i18n";

export default {
    data() {
        return {
            rules: {
                required: (value) => {
                    return !!value || i18n.global.t("Required")
                },
                email: (value) => {
                    return (
                        !value ||
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                            value
                        ) ||
                        i18n.global.t("Valid email error")
                    );
                },
                min: (value) =>
                    !value ||
                    value.length >= 6 ||
                    i18n.global.t("minChar", { minimum: 6 }),
                password: (value) =>
                    !value ||
                    (/^(?=.*[^a-zA-Z0-9])/.test(value) &&
                        /^(?=.*[a-z])/.test(value) &&
                        /^(?=.*[A-Z])/.test(value) &&
                        /^(?=.*[0-9])/.test(value)) ||
                    i18n.global.t("validPassword"),
                match: (value) => //Apply to main user
                    value === this.user.password ||
                    this.$t("Passwords do not match"),
                registration: (value) =>
                    !value ||
                    /^[A-Za-z]{3}-[0-9]{3}$/.test(value) ||
                    i18n.global.t("validRegistration"),
                typeNumber: (value) => typeof value !== 'number' || i18n.global.t('validNumber'),
                isNaN:  (value) => {
                    let removedCommaNumber = Number(value.toString().replace(/,/g, '.'))
                    const testValue = isNaN( removedCommaNumber )
                    return !testValue || i18n.global.t('validNumber')
                } ,
                typeNegativeNumber: (value) => !/[a-zA-Z]/g.test(value)|| i18n.global.t('validNumber'),
                negativeNumber: (value) => value > 0 || i18n.global.t('negativeNumber'),
                validateUsername: (value) => {
                    const allowedCharactersRegex = /^[a-zA-Z0-9_.@-]+$/;
                    const isValidCharacters = allowedCharactersRegex.test(value);

                    return isValidCharacters || i18n.global.t("username validation")
                },
                sharingCodes: (value) => value.length === 16 || i18n.global.t('sharing code validation'),
                textLength: (value) => value.length <= 150 || i18n.global.t('max150'),
                unitLength: (value) => value.length <= 10 || i18n.global.t('max10')
            },
        };
    },
};