<template>
    <!-- :rail ="!$route.meta.hideNavigation ? false : rail" -->
    <v-navigation-drawer
        v-model="openDrawer"
        style="z-index: 1000; height: 100%"
        color="primary"
        app
        :permanent="$vuetify.display.mdAndUp"
    >
        <v-list dense class="mt-10">
            <v-list-item v-if="!oidcIsAuthenticated" link router to="/">
                <v-list-item-action>
                    <v-icon>mdi-settings</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-icon>mdi-settings</v-icon>
                    <v-list-item-title class="text-white">{{
                        $t('Welcome')
                    }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <template v-if="oidcIsAuthenticated">
                <v-col class="nav d-flex flex-column">
                    <v-row class="top-nav pa-0 ma-0">
                        <div class="nav-item">
                            <v-list-item
                                v-if="
                                    $store.state.features &&
                                    $store.state.features.dashboard
                                "
                                link
                                router
                                to="/dashboard"
                                prepend-icon="mdi-view-dashboard-outline"
                                :title="$t('Dashboard')"
                            />

                            <v-list-item
                                v-if="
                                    $store.state.features &&
                                    $store.state.features.calculations
                                "
                                link
                                router
                                to="/calculations"
                                prepend-icon="mdi-calculator"
                                :title="$t('Calculations')"
                            />

                            <v-list-item
                                v-if="
                                    $store.state.features &&
                                    $store.state.features.drivers
                                "
                                link
                                router
                                to="/drivers"
                                prepend-icon="mdi-account-supervisor-outline"
                                :title="$t('Drivers')"
                            />

                            <v-list-group
                                v-if="
                                    $store.state.features &&
                                    $store.state.features.taskProfitability
                                "
                                value="tasks"
                            >
                                <template #activator="{ props }">
                                    <v-list-item
                                        v-bind="props"
                                        prepend-icon="mdi-calendar-check"
                                        :title="$t('Tasks')"
                                    />
                                </template>

                                <v-list-item
                                    link
                                    router
                                    to="/TaskProfitability"
                                    :title="$t('Tasks')"
                                />
                                <v-list-item
                                    link
                                    router
                                    to="/invoices"
                                    :title="$t('Invoices')"
                                />
                            </v-list-group>

                            <v-list-group
                                v-if="
                                    $store.state.features &&
                                    $store.state.features.timesheetManagement
                                "
                                value="timesheet"
                            >
                                <template #activator="{ props }">
                                    <v-list-item
                                        v-bind="props"
                                        prepend-icon="mdi-briefcase-outline"
                                        :title="$t('Shift')"
                                    />
                                </template>

                                <v-list-item
                                    link
                                    router
                                    to="/timesheet"
                                    :title="$t('Timesheet Management')"
                                />
                                <v-list-item
                                    link
                                    router
                                    to="/payperiods"
                                    :title="$t('payPeriod.label')"
                                />
                            </v-list-group>

                            <v-list-item
                                v-if="
                                    $store.state.features &&
                                    $store.state.features.routes
                                "
                                link
                                router
                                to="/routes"
                                prepend-icon="mdi-map-marker-path"
                                :title="$t('Route.title')"
                            />

                            <v-list-group
                                v-if="
                                    $store.state.features &&
                                    $store.state.features.vehicles &&
                                    ($store.state.features.sharing ||
                                        $store.state.features.map)
                                "
                                value="vehicles"
                            >
                                <template #activator="{ props }">
                                    <v-list-item
                                        v-bind="props"
                                        prepend-icon="mdi-truck-outline"
                                        :title="$t('Vehicles')"
                                    />
                                </template>

                                <v-list-item
                                    v-if="
                                        $store.state.features &&
                                        $store.state.features.vehicles
                                    "
                                    link
                                    router
                                    to="/vehicles"
                                    :title="$t('Vehicles')"
                                />
                                <v-list-item
                                    v-if="
                                        $store.state.features &&
                                        $store.state.features.sharing
                                    "
                                    link
                                    router
                                    to="/sharing"
                                    :title="$t('Sharing data.name')"
                                />
                                <v-list-item
                                    v-if="
                                        $store.state.features &&
                                        $store.state.features.map
                                    "
                                    link
                                    router
                                    to="/vehicle/location-map"
                                    :title="$t('map.locationTracking')"
                                >
                                    <template #append>
                                        <div class="beta-chip">Beta</div>
                                    </template>
                                </v-list-item>
                            </v-list-group>

                            <v-list-item
                                v-else-if="
                                    $store.state.features &&
                                    $store.state.features.vehicles
                                "
                                link
                                router
                                to="/vehicles"
                                prepend-icon="mdi-truck-outline"
                                :title="$t('Vehicles')"
                            />

                            <v-list-item
                                v-if="
                                    $store.state.features &&
                                    $store.state.features.reports
                                "
                                link
                                router
                                to="/report"
                                prepend-icon="mdi-file-chart"
                                :title="$t('report')"
                            />

                            <v-list-item
                                v-if="
                                    $store.state.features &&
                                    $store.state.features.analytics
                                "
                                link
                                router
                                to="/analytics"
                                prepend-icon="mdi-google-analytics"
                                :title="$t('Analytics')"
                            />

                            <div
                                v-if="!$route.meta.hideNavigation"
                                class="hidden-navigation"
                            >
                                <v-list-item
                                    density="compact"
                                    link
                                    router
                                    to="calculation"
                                    :class="{
                                        'v-list-item--active':
                                            calculationActive,
                                    }"
                                    :title="$t('Calculation')"
                                />
                            </div>

                            <div v-if="!$route.meta.hideNavigation">
                                <v-list-item
                                    density="compact"
                                    link
                                    router
                                    to="ProfitCalculationDetails"
                                    :title="$t('Profit')"
                                />
                            </div>

                            <div v-if="!$route.meta.hideNavigation">
                                <v-list-item
                                    density="compact"
                                    link
                                    router
                                    to="TimeBasedCosts"
                                    :title="$t('Time Based Cost')"
                                />
                            </div>

                            <div v-if="!$route.meta.hideNavigation">
                                <v-list-item
                                    density="compact"
                                    link
                                    router
                                    to="DistanceBasedCosts"
                                    :title="$t('Distance Based Cost')"
                                />
                            </div>

                            <div v-if="!$route.meta.hideNavigation">
                                <v-list-item
                                    density="compact"
                                    link
                                    router
                                    to="calculationResultsVue"
                                    :title="$t('CombinationCosts')"
                                />
                            </div>

                            <div v-if="!$route.meta.hideNavigation">
                                <v-list-item
                                    density="compact"
                                    link
                                    router
                                    to="pricing"
                                    :title="$t('Pricing')"
                                />
                            </div>
                        </div>
                    </v-row>

                    <v-row
                        v-if="!rail"
                        class="justify-start d-flex flex-column"
                    >
                        <v-list-item
                            prepend-icon="mdi-logout"
                            :title="$t('Sign out')"
                            @click="$store.dispatch('logout')"
                        />
                        <span class="copyright ml-4"
                            >&copy; {{ year }} Diffo Solutions Oy</span
                        >
                    </v-row>
                </v-col>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import BetaMixin from '../mixins/betaMixin';
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'ProfitSidebar',

    mixins: [BetaMixin],
    props: {
        drawer: {
            type: Boolean,
        },
    },
    data: () => ({
        //  drawer: this.value
        calculationActive: false,
        calcNames: [
            'SelectCalculationType',
            'Revenue',
            'SelectInvestmentType',
            'VehicleDetails',
            'Investments',
            'InsertWages',
            'Calculation',
        ],
        openDrawer: true,
        rail: true,
    }),
    computed: {
        ...mapGetters('oidcStore', ['oidcIsAuthenticated']),
        year() {
            return new Date().getFullYear();
        },
        showCopyRight() {
            return this.rail;
        },
    },
    watch: {
        $route() {
            this.calculationActive = this.calcNames.includes(this.$route.name);
        },
        drawer() {
            this.openDrawer = this.drawer;
        },
    },
    created() {
        this.openDrawer = this.drawer;
    },
    methods: {
        ...mapActions('oidcStore', ['signOutOidc']),
    },
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
    z-index: 1;
    padding-top: 0;
    position: fixed;
    padding-bottom: 24px;
}

.v-navigation-drawer__scrim {
    display: none !important;
}
.hidden-navigation {
    padding: 16px 0 0;
}
.list-group {
    width: 100% !important;
}

::v-deep .v-icon {
    color: #fff !important;
}

::v-deep .v-list .v-list-item--active {
    color: #fff;
}

::v-deep .v-list-item__subtitle {
    font-size: 14px !important;
}

.nav {
    height: 100vh;
    padding: 0 !important;
}

::v-deep .v-list-item--dense .v-list--dense .v-list-item {
    min-height: 0 !important;
}
.copyright {
    font-size: 12px;
    color: #eee;
    opacity: 0.7;
    margin-left: -12px;
}

.logout {
    cursor: pointer;
}
.nav-item {
    width: 100% !important;
}

.beta-chip {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #52b788;
    border-radius: 0.5rem;
    padding: 0.5rem;
    max-width: 2.25rem;
    max-height: 1.25rem;
    font-size: small;
}
</style>
