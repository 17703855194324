export default {
    methods: {
        downloadCSV(rows, name) {
            const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent("\uFEFF" + rows.map(e => e.join(";")).join("\n"));
            // Add \uFEFF at the beginning to specify the UTF-8 encoding and prevent Excel from misinterpreting the characters
          
            const link = document.createElement('a');
            link.setAttribute('href', csvContent);
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
          }
          
    }
}