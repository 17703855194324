export default {
    data() {
        return {
            emissionStandardMixin: [
                {
                    title: this.$i18n.t("No emission standard"),
                    value: 0
                },
                {
                    title: "EURO_I",
                    value: 1
                },
                {
                    title: "EURO_II",
                    value: 2
                },
                {
                    title: "EURO_III",
                    value: 3
                },
                {
                    title: "EURO_IV",
                    value: 4
                },
                {
                    title: "EURO_V",
                    value: 5
                },
                {
                    title: "EURO_VI",
                    value: 6
                },
                {
                    title: "EURO_VII",
                    value: 7
                },
                {
                    title: "EURO_III_EEV",
                    value: 8
                },
            ],
        };
    },
};
