import { mapGetters } from "vuex";

export default {
    data() {
        return {
            featureTemplate: {
                externalServiceIntegration: false,
                taskProfitability: false,
                api: false,
            },
            defaultRoles: ["Admin", "Superuser", "User"],
            defaultClientRoles: ["Vueapp", "Driverapp", "Diffoapi"],
            defaultLanguages: [
                { name: "English", short: "en" },
                { name: "Swedish", short: "sv" },
                { name: "Finnish", short: "fi" },
            ],
        };
    },
    methods: {
        catchError(error) {
            if (error.response) {
                console.log(error.response.status);
                // if(error.response.data)
                //   console.log(error.response.data);
            } else if (error.request) {
                console.log("get error", error.request);
            } else {
                console.error("Error", error.message);
            }
        },
    },
    computed: {
        ...mapGetters(["roles", "features"]),
        superOrHigher() {
            return (
                this.roles.includes("Superuser") || this.roles.includes("Admin")
            );
        },
    },
};
